import styled from "styled-components"

export const BlogLayout = styled.div`
  --gray: #2c3842;
  --light-gray: #323f4b;
  --very-light-gray: #e9e9e9;

  cursor: default;

  img {
    /* border-radius: 0.25rem; */
  }

  .blog-title {
    margin: 0;
    text-align: center;

    font-size: 3.5rem;
    font-weight: 500;
  }

  .subtitle {
    text-align: center;

    color: gray;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
  }

  .tag-container {
    margin-bottom: 1rem;
    text-align: center;

    a,
    span {
      margin-right: 0.5rem;
      text-decoration: none;
      border-radius: 0.25rem;
    }

    .live {
      padding: 0.4rem 0.5rem;

      background: black;
      color: whitesmoke;
      /* font-weight: 600; */
      font-size: 0.8rem;
    }
  }

  .tech-stack {
    text-align: center;
    margin-bottom: 0.5rem;

    .item {
      padding: 0.1rem 0.3rem;
      margin: 0 0.25rem;

      background: var(--very-light-gray);
      font-size: 0.8rem;
      font-family: "JetBrains Mono";
    }
  }
`
