import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import { BlogLayout } from "../../components/BlogLayout"

const imagePrefix = "/projects/mujik"

function mujik(props) {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="mujik" />
      <BlogLayout>
        <div className="blog-title">mujik</div>

        <div className="tag-container">
          <a href="https://mujik.aloo.pw" className="live">
            Live on <strong>mujik.aloo.pw</strong>
          </a>
          <a href="https://github.com/mujik-team/mujik-web" className="live">
            ⚙️ src
          </a>
        </div>

        <div className="tech-stack">
          {technicalStack.map(t => (
            <span className="item">{t}</span>
          ))}
        </div>

        <hr />

        <p>
          A social media network dedicated to music. Integrates with Spotify and
          allows users to compete in games and tournaments while discovering new
          music along the way!
        </p>

        {/* <p>
          <strong>Needs re-write!</strong> Two of my friends and I created a
          social media platform focused on sharing and discovering music with
          others (w/ support for Spotify). It was for one of classes (shout-out
          to Prof. McKenna) and we are quite proud of what we were able to
          accomplish in the span of a month. We were given the task of creating
          a music player using either YouTube or Spotify's APIs and add some
          sort of twist. Along with developing the project we had to give a
          presentation for "stakeholders".
        </p> */}

        <img src={`${imagePrefix}/tourney_explain.png`} />
        <p className="tag">One of the presentation slides.</p>

        <p>
          For <strong>mujik</strong> the twist was the ability to create
          tournaments where users could compete with their mixtapes. By
          participating in tournaments users could earn coins which could be
          redeemed for rewards such as custom backgrounds for their profiles or
          GIFs as avatars.
        </p>

        <hr />

        <h3>Screenshots</h3>

        <img src={`${imagePrefix}/home.png`} />
        <p className="tag">Home Page</p>

        <img src={`${imagePrefix}/library.png`} />
        <p className="tag">User Library</p>

        <img src={`${imagePrefix}/create_tourney.png`} />
        <p className="tag">Tourney Creation</p>

        <img src={`${imagePrefix}/browse_tourney.png`} />
        <p className="tag">Tourney Browse</p>
      </BlogLayout>
    </Layout>
  )
}

export default mujik

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const technicalStack = ["React + Hooks", "Node.JS", "express", "MongoDB"]
